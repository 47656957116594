import { useParams } from 'react-router-dom'
import StripeCompleted from './StripeCompleted';

export default function StripeCompletedFunc() {

    const { uid }: any = useParams()

    return (
        <StripeCompleted uid={uid} />
    );
}