import { Theme } from "@mui/material";
import CenteredImage from "./CenteredImage";
import fitfrenLogo from '../img/fitfren_logo.png';

type FitfrenLogoProps = {
    theme: Theme
}

export default function FitfrenLogo(props: FitfrenLogoProps) {
    return <CenteredImage src={fitfrenLogo} sx={{
        [props.theme.breakpoints.down('sm')]: {
            maxWidth: '70%',
        },
        [props.theme.breakpoints.only('sm')]: {
            maxWidth: '50%',
        },
        [props.theme.breakpoints.up('md')]: {
            maxWidth: '20%',
        },
    }} />
}