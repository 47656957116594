import { SxProps } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";
import styled from "@mui/material/styles/styled"

const StyledImage = styled('img')({
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
});

type CenteredImageProps = {
    src: string,
    sx?: SxProps<Theme>,
}

export default function CenteredImage(props: CenteredImageProps) {
    return (
        <StyledImage sx={props.sx} src={props.src}></StyledImage>
    )
}