import { Component } from "react"
import { StyledFirebaseAuth } from "react-firebaseui";
import { FacebookAuthProvider, getAuth, OAuthProvider, onAuthStateChanged } from 'firebase/auth';
import MyPage from "./MyPage";

const uiConfig = {
    signInOptions: [
        new OAuthProvider('apple.com').providerId,
        FacebookAuthProvider.PROVIDER_ID
    ],
    signInFlow: 'popup',
    callbacks: {
        signInSuccessWithAuthResult: () => false,
    },
};

type LoginProps = {
}

type LoginState = {
    loggedIn: boolean;
}

export default class Login extends Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);
        this.state = { loggedIn: false };
    }

    async componentDidMount() {
        onAuthStateChanged(getAuth(), user => {
            if (user) {
                this.setState({ loggedIn: true })
            }
        });
    }

    render() {
        if (this.state.loggedIn) {
            return (
                <MyPage />
            )
        }
        else {
            return (
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
            )
        }
    }

}