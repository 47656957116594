import { Link, Theme } from "@mui/material";
import CenteredImage from "./CenteredImage";
import instagramLogo from '../img/front_page/Instagram_logo.svg';

type InstagramLogoProps = {
    theme: Theme
}

export default function InstagramLogo(props: InstagramLogoProps) {
    return <Link href="https://www.instagram.com/fitfren_app/">
        <CenteredImage src={instagramLogo} sx={{
            [props.theme.breakpoints.down('sm')]: {
                maxWidth: '60%',
            },
            [props.theme.breakpoints.only('sm')]: {
                maxWidth: '40%',
            },
            [props.theme.breakpoints.up('md')]: {
                maxWidth: '20%',
            },
        }} /></Link>
}