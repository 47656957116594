import { Component } from "react";
import { CircularProgress } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

type StripeCompletedProps = {
    uid: string
}

type StripeCompletedState = {
}

export default class StripeCompleted extends Component<StripeCompletedProps, StripeCompletedState> {

    async componentDidMount() {
        const functions = getFunctions(undefined, 'europe-west1');
        httpsCallable(functions, 'markInstructorLinked')({ uid: this.props.uid })
            .then((_result) => {
                window.location.replace('FitfrenApp://');
            })
            .catch((_error) => {
                this.setState(({ hasFailed: true }));
            });
    }

    render() {
        return <CircularProgress />;
    }

}