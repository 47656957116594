import { useParams } from 'react-router-dom'
import StripeRegister from './StripeRegister';

export default function StripeRegisterFunc() {

    const { uid }: any = useParams()

    return (
        <StripeRegister uid={uid} />
    );
}