import { Link, Theme } from "@mui/material";
import CenteredImage from "./CenteredImage";
import appStore from '../img/front_page/App_store.svg';

type AppStoreProps = {
    theme: Theme
}

export default function AppStore(props: AppStoreProps) {
    return <Link href="https://apps.apple.com/us/app/fitfren/id1468506094">
        <CenteredImage src={appStore} sx={{
            [props.theme.breakpoints.down('sm')]: {
                maxWidth: '90%',
            },
            [props.theme.breakpoints.only('sm')]: {
                maxWidth: '80%',
            },
            [props.theme.breakpoints.up('md')]: {
                maxWidth: '40%',
            },
        }} />
    </Link>
}