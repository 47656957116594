import { getAuth } from "firebase/auth";
import { Component } from "react"
import { User } from "./Types/User";

type MyPageProps = {
}

type MyPageState = {
    user?: User
}

export default class MyPage extends Component<MyPageProps, MyPageState> {

    async componentDidMount() {
        this.setState({ user: await User.getFromFirestore(getAuth().currentUser!.uid) });
    }

    render() {
        return <div>
            <p> More coming soon </p>
        </div>
    }

}