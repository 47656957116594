import { Component } from "react"

import { getFunctions, httpsCallable } from "firebase/functions";
import { CircularProgress } from "@mui/material";


type StripeRegisterProps = {
    uid: string
}

type StripeRegisterState = {
    hasFailed: boolean;
}

export default class StripeRegister extends Component<StripeRegisterProps, StripeRegisterState> {

    constructor(props: StripeRegisterProps) {
        super(props);
        this.state = { hasFailed: false };
    }

    async componentDidMount() {

        const functions = getFunctions(undefined, 'europe-west1');
        httpsCallable(functions, 'createStripeAccount')({ uid: this.props.uid })
            .then((result) => {
                const accountLink: any = result.data;
                window.location.href = accountLink.url;
            })
            .catch((error) => {
                this.setState(({ hasFailed: true }));
            });
    }

    render() {
        if (this.state.hasFailed) {
            return <p>An unexpected error occured</p>
        }
        return <CircularProgress />;
    }

}