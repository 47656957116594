import { BrowserRouter, Switch, Route } from "react-router-dom";
import StripeRegisterFunc from "./Stripe/StripeRegisterFunc";
import StripeCompletedFunc from "./Stripe/StripeCompletedFunc";
import Support from "./Support";
import FrontPage from "./FrontPage/FrontPage";
import Login from "./Login";
import DataDeletion from "./DataDeletion";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/stripe/:uid'>
          <StripeRegisterFunc />
        </Route>
        <Route path='/stripe_completed/:uid'>
          <StripeCompletedFunc />
        </Route>
        <Route path='/support'>
          <Support />
        </Route>
        <Route path='/data_deletion'>
          <DataDeletion />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path="/">
          <FrontPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
