import React from 'react';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom';
import App from './App';

const firebaseConfig = require('./firebase-config.json').result.sdkConfig;
initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
