import { AppBar, Box, Button, createTheme, CssBaseline, Grid, Paper, Toolbar } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Link } from "react-router-dom";
import AppStore from "./AppStore";
import CenteredImage from "./CenteredImage";
import FitfrenLogo from "./FitfrenLogo";
import img1 from '../img/front_page/front_page_1.jpg';
import img2 from '../img/front_page/front_page_2.jpg';
import img3 from '../img/front_page/front_page_3.jpg';
import InstagramLogo from "./InstagramLogo";

const theme = createTheme();

const LoginLink = styled(Link)({
})

const FooterLink = styled(Link)({
  flexGrow: 1,
})

export default function FrontPage() {
  return (
    <Box>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar>
          <Grid container direction="row" alignItems="center" columns={4} >
            <Grid item xs={1}>
              <FitfrenLogo theme={theme} />
            </Grid>
            <Grid item xs={1}>
              <AppStore theme={theme} />
            </Grid>
            <Grid item xs={1}>
              <InstagramLogo theme={theme} />
            </Grid>
            <Grid item xs={1}>
              <LoginLink to="/login">
                <Button sx={{ width: '100%', backgroundColor: 'primary.light' }} variant="contained">Login</Button>
              </LoginLink>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <CenteredImage sx={{ width: '100%' }} src={img1} />
      <CenteredImage sx={{ width: '100%' }} src={img2} />
      <CenteredImage sx={{ width: '100%' }} src={img3} />
      <Paper sx={{
        width: '100%',
        bottom: 0,
        justifyContent: "center",
        display: "flex",
      }} component="footer" square variant="outlined">
        <FooterLink to="/support">Support</FooterLink>
        <FooterLink to="/data_deletion">Data deletion</FooterLink>
        <a href='./docs/fitfren-privacy-policy.pdf' target="_blank">Privacy</a>
      </Paper>
    </Box>
  )
}