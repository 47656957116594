import { doc, getDoc, getFirestore } from "firebase/firestore";

export class User {

    constructor(uid: string, name: string, email: string) {
        this.uid = uid;
        this.name = name;
        this.email = email;
    }

    uid: string;
    name: string;
    email: string;

    static async getFromFirestore(uid: string): Promise<User> {
        const docRef = doc(getFirestore(), "users", uid);
        const docSnap = await getDoc(docRef);
        return new User(docSnap.id, docSnap.get("name"), docSnap.get("email"));
    }
}